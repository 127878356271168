exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-api-and-integration-index-tsx": () => import("./../../../src/pages/apiAndIntegration/index.tsx" /* webpackChunkName: "component---src-pages-api-and-integration-index-tsx" */),
  "component---src-pages-cms-integration-index-tsx": () => import("./../../../src/pages/cmsIntegration/index.tsx" /* webpackChunkName: "component---src-pages-cms-integration-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-ecommerce-development-index-tsx": () => import("./../../../src/pages/ecommerceDevelopment/index.tsx" /* webpackChunkName: "component---src-pages-ecommerce-development-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mobile-app-index-tsx": () => import("./../../../src/pages/mobileApp/index.tsx" /* webpackChunkName: "component---src-pages-mobile-app-index-tsx" */),
  "component---src-pages-technology-index-tsx": () => import("./../../../src/pages/technology/index.tsx" /* webpackChunkName: "component---src-pages-technology-index-tsx" */),
  "component---src-pages-web-app-index-tsx": () => import("./../../../src/pages/webApp/index.tsx" /* webpackChunkName: "component---src-pages-web-app-index-tsx" */),
  "component---src-pages-web-design-index-tsx": () => import("./../../../src/pages/webDesign/index.tsx" /* webpackChunkName: "component---src-pages-web-design-index-tsx" */)
}

